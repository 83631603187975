<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div>
              <el-button type="primary" @click="add_user">{{
                $t("Add")
              }}</el-button>
            </div>
            <p class="p-3" v-if="userlist">
              <el-table
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="userlist"
                @row-click="row_clicked_event"
              >
                <!--el-table-column label="IDn" prop="IDn">
                  <template v-slot="{ row }">
                    {{ row.id }}
                  </template>
                </el-table-column-->
                <el-table-column
                  :label="$t('Username')"
                  prop="username"
                ></el-table-column>
                <el-table-column :label="$t('Roles')">
                  <template v-slot="{ row }">
                    <span
                      v-for="(role, rolename) in row.rolelist"
                      :key="rolename"
                      class="rolespan"
                      > {{ role }} </span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Status')">
                  <template v-slot="{ row }">
                    <span v-if="row.status">{{ $t("Active") }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="">
                  <template v-slot="{ row }">
                    <b-button
                      v-if="row.status"
                      variant="danger"
                      size="sm"
                      @click.prevent="user_status($event, row.id, 0)"
                      >{{ $t("Disable") }}</b-button
                    >
                    <b-button
                      v-else
                      variant="success"
                      size="sm"
                      @click.prevent="user_status($event, row.id, 1)"
                      >{{ $t("Active") }}</b-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col cols="2">
                    <base-input :label="$t('Username')">
                      <b-input
                        placeholder="UserName"
                        class="form-control"
                        v-model="cur_user.username"
                      />
                    </base-input>
                  </b-col>
                  <b-col cols="2">
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Password')">
                          <b-input
                            placeholder="Password"
                            class="form-control"
                            v-model="cur_user.password"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('Roles')">
                      <b-form-checkbox-group
                        id="storelist2"
                        v-model="cur_user.rolelist"
                        inline="true"
                        :options="rolelistOptions"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="justify-content-lg-center mt-4">
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import InputAutocomplete from "../InputAutocomplete";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
    InputAutocomplete,
  },
  data() {
    return {
      currpage: "list",
      cur_user: null,
      cur_opt: null,
      new_user: {
        id: "",
        username: "",
        password: "",
        rolelist: [],
      },

      userlist: null,
      rolelist: null,
      rolelistOptions: [],

      token: "",
      user_id: 0,
    };
  },
  methods: {
    add_user() {
      this.currpage = "edit";
      this.cur_user = JSON.parse(JSON.stringify(this.new_user));
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      if (column.label == "") {
        return;
      }
      this.currpage = "edit";
      this.cur_user = JSON.parse(JSON.stringify(row));
      this.cur_user.password = "";
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async user_status(event, uid, status) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      bodyFormData.append("id", uid);
      bodyFormData.append("status", status);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Staff/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_user) {
        if (key == "rolelist") {
          if (this.cur_user[key])
            bodyFormData.append(key, JSON.stringify(this.cur_user[key]));
        } else {
          if (this.cur_user[key]) bodyFormData.append(key, this.cur_user[key]);
        }
      }

      axios({
        method: "post",
        url: "/Api/Web/Staff/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async get_user_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Staff",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.userlist = rt.data.users;
              for (var i=0; i<that.userlist.length; i++) {
                if (that.userlist[i].rolelist) {
                  that.userlist[i].rolelist = JSON.parse(that.userlist[i].rolelist);
                } else {
                  that.userlist[i].rolelist = [];
                }
              }
              that.rolelist = rt.data.roles;
              that.rolelistOptions = [];
              for (var key in that.rolelist) {
                if (that.rolelist.hasOwnProperty(key)) {
                  that.rolelistOptions.push({
                    html:
                      '<span class="select_text">' +
                      that.rolelist[key] +
                      "</span>",
                    value: key,
                  });
                }
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/weblogin");
        return;
      }
      return this.get_user_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.search-button {
  margin-top: 2rem;
}
.password_setting {
  padding-top: 2rem;
}
.rolespan {
  border: 1px solid #e0e0e0;
  padding: 0 1px;
}
.select_text {
  margin-left: -18px;
}
.number_password {
  margin-top: -18px;
}
</style>
